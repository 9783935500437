import { t } from '@owl-nest/localize'
import * as plume from '@ulule/owl-kit-components/next'

import { type Message, useMessagesContext } from './messagesContext'

type ComponentType = 'toast' | 'callout' | 'flash'
type ActionPropsBase = {
  context: string
  message: React.ReactNode
  onClose?: () => void
  forceClose?: boolean
  zIndexLevel?: 'high' | 'low'
} & (ActionLinkProps | ActionButtonProps)

type ActionLinkProps = {
  button?: undefined
  link?: plume.LinkProps
}

type ActionButtonProps = {
  button?: plume.LinkAsButtonProps
  link?: undefined
}

export function useMessage(contexts: string[] = []) {
  const { dispatch, state } = useMessagesContext()
  const messagesFromState = contexts
    .map((context) => {
      return state[context]
    })
    .filter((message) => message !== undefined)

  function alert(props: ActionPropsBase & { type: ComponentType }): void {
    create({ level: 'alert', ...props })
  }

  function clear(context: string): void {
    dispatch({ type: 'clear', context })
  }

  function confirm(props: ActionPropsBase & { type: ComponentType }): void {
    create({ level: 'success', ...props })
  }

  function create(message: Message): void {
    dispatch({ type: 'create', message })
  }

  function error(props: ActionPropsBase & { type: ComponentType }): void {
    create({ level: 'error', ...props })
  }

  function inform(props: ActionPropsBase & { type: ComponentType }): void {
    create({ level: 'info', ...props })
  }

  // TODO : add focus
  function internalError(props: Omit<ActionPropsBase, 'message'> & { type: ComponentType }): void {
    create({ message: t('Oops, an internal error has occurred... Please retry!'), level: 'error', ...props } as Message)
  }

  function notification(props: ActionPropsBase & { type: 'callout' | 'flash' }): void {
    create({ level: 'notification', ...props })
  }

  function softDelete(context: string): void {
    dispatch({ type: 'soft-delete', context })
  }

  return {
    alert,
    clear,
    confirm,
    create,
    error,
    inform,
    internalError,
    messages: messagesFromState,
    notification,
    softDelete,
  }
}
