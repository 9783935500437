import * as React from 'react'
import styled, { css } from 'styled-components'

import * as plume from '@ulule/owl-kit-components/next'

import { type MessageToast, useMessage } from '../index'

type Position = 'bottom' | 'top'

type ToastDisplayerProps = {
  className?: string
  contexts?: string[]
  position: Position
  offset?: number
}

export function ToastDisplayerContainer({
  className,
  contexts = [],
  position,
}: ToastDisplayerProps): React.ReactElement<ToastDisplayerProps> {
  const { messages, clear, softDelete } = useMessage(contexts)

  React.useEffect(() => {
    const timers = messages.map((message) => {
      if (message.type === 'toast' && message.forceClose && message.deleted === false) {
        return setTimeout(() => {
          softDelete(message.context)
        }, message.timeOpen)
      }
    })

    return () => {
      timers.forEach((timer) => {
        if (timer) {
          clearTimeout(timer)
        }
      })
    }
  }, [messages])

  const currentUsedContexts = messages.reduce((acc, message) => {
    if (!acc.includes(message.context)) {
      return [...acc, message.context]
    }

    return acc
  }, [] as string[])

  const isMultiContext = currentUsedContexts.length > 1

  const highMessages = messages.filter(
    (message) => message.type === 'toast' && message.zIndexLevel === 'high',
  ) as MessageToast[]
  const lowMessages = messages.filter(
    (message) => message.type === 'toast' && (message.zIndexLevel === 'low' || message.zIndexLevel === undefined),
  ) as MessageToast[]

  return (
    <>
      <Wrapper
        className={className}
        isAllDeleted={highMessages.every((message) => message.deleted)}
        isMultiContext={isMultiContext}
        level="high"
      >
        {highMessages.map((message, i) => {
          const toastProps = {
            key: `${message.context}-${i}`,
            type: message.level,
            onClose: () => {
              clear(message.context)
              message.onClose && message.onClose()
            },
            closed: message.deleted ?? false,
          }

          if (message.link) {
            return (
              <plume.Toast {...toastProps} link={message.link}>
                {message.message}
              </plume.Toast>
            )
          }

          if (message.button) {
            return (
              <plume.Toast {...toastProps} button={message.button}>
                {message.message}
              </plume.Toast>
            )
          }

          return <plume.Toast {...toastProps}>{message.message}</plume.Toast>
        })}
      </Wrapper>
      <Wrapper
        className={className}
        isAllDeleted={lowMessages.every((message) => message.deleted)}
        isMultiContext={isMultiContext}
        level="low"
      >
        {lowMessages.map((message, i) => {
          const toastProps = {
            key: `${message.context}-${i}`,
            type: message.level,
            onClose: () => {
              clear(message.context)
              message.onClose && message.onClose()
            },
            closed: message.deleted ?? false,
          }

          if (message.link) {
            return (
              <plume.Toast {...toastProps} link={message.link}>
                {message.message}
              </plume.Toast>
            )
          }

          if (message.button) {
            return (
              <plume.Toast {...toastProps} button={message.button}>
                {message.message}
              </plume.Toast>
            )
          }

          return <plume.Toast {...toastProps}>{message.message}</plume.Toast>
        })}
      </Wrapper>
    </>
  )
}

export const Wrapper = styled.div<{ isAllDeleted: boolean; isMultiContext: boolean; level: 'low' | 'high' }>`
  ${({ isMultiContext }) => {
    if (isMultiContext) {
      return css`
        ${plume.styles.notification.ToastContainer} {
          position: absolute;
          width: 100%;
        }
      `
    }
  }}

  ${({ level }) => {
    if (level === 'high') {
      return css`
        z-index: ${plume.ZINDEX.DRAWER + 1};
      `
    }

    if (level === 'low') {
      return css`
        z-index: ${plume.ZINDEX.TOAST};
      `
    }
  }}

  ${({ isAllDeleted }) =>
    isAllDeleted &&
    css`
      pointer-events: none;
    `}
`

export const ToastDisplayer = styled(ToastDisplayerContainer)`
  position: fixed;
  transform: translateX(-50%);
  width: 90%;
  left: 50%;

  @media screen and ${plume.BREAKPOINTS.TABLET} {
    ${({ offset = plume.SIZES.HEADER_HEIGHT }) => {
      return css`
        top: calc(${offset}px + 30px);
      `
    }}

    margin: 0;
    margin-right: 10px;
    width: 550px;
  }

  ${({ offset = plume.SIZES.HEADER_HEIGHT, position }) => {
    if (position === 'bottom') {
      return css`
        bottom: 10px;
      `
    }

    if (position === 'top') {
      return css`
        top: calc(${offset}px + 10px);

        @media screen and ${plume.BREAKPOINTS.TABLET} {
          top: calc(${offset}px + 30px);
        }
      `
    }
  }}
`
