import * as React from 'react'
import styled from 'styled-components'

import * as copy from '../../styles/copy'
import * as notification from '../../styles/notification'
import { useTransition } from '../../behaviours'

import { Link, type LinkProps } from '../Link'
import { LinkAsButton, LinkAsButtonProps } from '../button/LinkAsButton'

import { Base, type BaseProps } from './Base'

export type ToastProps = BaseProps & {
  closed: boolean
  onClose?: () => void
  wrapWithCopy?: boolean
} & (ToastLinkProps | ToastButtonProps)

type ToastLinkProps = {
  button?: undefined
  link?: LinkProps
}

type ToastButtonProps = {
  button?: LinkAsButtonProps
  link?: undefined
}

function ToastComponent({
  button,
  children,
  className,
  closed,
  customIcon,
  link,
  onClose,
  type,
  wrapWithCopy = true,
}: ToastProps): React.ReactElement<ToastProps> {
  const { step, unmounted, onTransitionEnd } = useTransition(!closed)

  React.useEffect(() => {
    if (closed && onClose) {
      onClose()
    }
  }, [unmounted])

  return (
    <Base className={className} customIcon={customIcon} type={type} onTransitionEnd={onTransitionEnd} step={step}>
      <notification.ToastContentWrapper>
        {wrapWithCopy ? <copy.S>{children}</copy.S> : children}
      </notification.ToastContentWrapper>
      {link && <ToastLink {...link} />}
      {button && <ToastLinkAsButton {...button} />}
    </Base>
  )
}

const ToastLink = styled(Link)`
  white-space: nowrap;
  margin-left: 12px;
`

const ToastLinkAsButton = styled(LinkAsButton)`
  white-space: nowrap;
  margin-left: 12px;
  padding: 0;
`

export const Toast = styled(ToastComponent)<ToastProps>``
Toast.displayName = 'Toast'
