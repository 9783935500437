import * as React from 'react'
import styled from 'styled-components'

import * as glyphs from '../../../icons/glyphs'
import * as styles from '../../../styles'

import { RoundButton } from '../../RoundButton'

import { ProjectCard, type ProjectCardProps } from './ProjectCard'

export type ProjectCardSkeletonProps = {
  className?: string
  picture?: string
  srcSet?: string
  size?: ProjectCardProps['size']
}

function ProjectCardSkeletonComponent({
  className,
  picture,
  size,
  srcSet,
}: ProjectCardSkeletonProps): React.ReactElement {
  return (
    <ProjectCard
      action={{ text: '', icon: 'active-heart', isCompact: true }}
      className={className}
      currency=" "
      goal="0"
      language=" "
      owner=" "
      picture={picture}
      rates={{}}
      size={size}
      srcSet={srcSet}
      targetCurrency=" "
      title=" "
      translation={{
        backerCount: ' ',
        comingSoon: ' ',
        finished: ' ',
        highlights: ' ',
        progressInfo: ' ',
        shortCountdown: ' ',
      }}
      type="presale"
    />
  )
}

export const ProjectCardSkeleton = styled(ProjectCardSkeletonComponent)`
  /* Applying skeleton styles */
  ${styles.card.project.FooterText},
  ${styles.card.project.Owner},
  ${styles.card.project.ProjectCardHeader},
  ${styles.card.project.Title} {
    color: transparent;

    ${styles.skeleton.skeletonAnimationRules}
  }

  /* ProjectCard styles tweak  */
  cursor: initial;

  ${styles.card.project.FooterText} {
    &:first-of-type {
      width: 70px;
    }

    &:last-of-type {
      height: ${({ size }) => (size === 'large' ? '22px' : '16px')};
      width: 20px;
    }
  }

  ${glyphs.Icon} {
    opacity: 0;
  }

  ${styles.card.project.Owner} {
    width: 100px;
    height: 12px;
  }

  ${RoundButton} {
    display: none;
  }

  ${styles.card.project.Title} {
    height: 20px;
    margin-top: 4px;
  }

  && {
    ${styles.card.project.ProjectCardPicture} {
      opacity: 0;
    }

    &:hover {
      ${styles.card.project.Title} {
        color: transparent !important;
      }

      ${styles.card.project.ProjectCardPicture} {
        opacity: 0;
      }
    }
  }

  ${styles.card.project.ProjectCardFooter} {
    padding: 10px 16px;
  }
`
